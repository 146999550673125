import FormHeader from '../../components/forms/FormHeader';
import { NavigateFunction, useNavigate } from 'react-router-dom';

const PasswordRequestSent = () => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <>
      <FormHeader
        title={`Demande envoyée !`}
        subTitle={`Si votre compte a bien été créé et confirmé, une demande de réinitialisation de votre mot de passe vous a été envoyée par e-mail.`}
        subSubTitle={`Vérifiez dans votre boite mail que votre compte a bien été créé et confirmé.`}
      />
      <div onClick={() => navigate(`/login`)} className='mt-3 cursor-pointer text-center font-bold text-green-secondary hover:underline'>
        Retour à la page de connexion
      </div>
    </>
  );
};

export default PasswordRequestSent;
