import React, { FC, SVGProps } from 'react';

export const Wrong: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={25} height={25} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Frame 48098175'>
        <path
          id='Subtract'
          opacity={0.5}
          fillRule='evenodd'
          clipRule='evenodd'
          d='M14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2C11.3137 2 14 4.68629 14 8ZM4.86726 11.1386C4.60841 10.8798 4.60841 10.4601 4.86726 10.2012L7.06264 8.00586L4.86205 5.80527C4.6032 5.54642 4.6032 5.12675 4.86205 4.86791C5.12089 4.60906 5.54057 4.60906 5.79941 4.86791L8 7.0685L10.2006 4.86791C10.4594 4.60906 10.8791 4.60906 11.138 4.86791C11.3968 5.12675 11.3968 5.54642 11.138 5.80527L8.93737 8.00586L11.1327 10.2012C11.3916 10.4601 11.3916 10.8798 11.1327 11.1386C10.8739 11.3975 10.4542 11.3975 10.1954 11.1386L8 8.94322L5.80462 11.1386C5.54577 11.3975 5.1261 11.3975 4.86726 11.1386Z'
          fill='#7988A2'
        />
      </g>
    </svg>
  );
};
