import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import React from 'react';
import * as Icons from '../../../components/icons';
import { EditedApp, Project } from '../../../services/apiConnections/types';

type ProjectDropdownProps = {
  fullWitdth?: boolean;
  projects: Project[];
  connexionProjectId?: string;
  setConnexionProjectId?: React.Dispatch<React.SetStateAction<string>>;
  editedApp?: EditedApp;
  setEditedApp?: React.Dispatch<React.SetStateAction<EditedApp | undefined>>;
};

const ProjectDropdown = ({
  fullWitdth,
  projects,
  connexionProjectId,
  setConnexionProjectId,
  editedApp,
  setEditedApp,
}: ProjectDropdownProps) => {
  return (
    <Menu as={'div'} className={`relative w-full self-start`}>
      <MenuButton
        className={`mb-2 flex ${fullWitdth ? 'w-full' : 'w-full min-xs:w-[350px]'} flex-row items-center justify-between rounded-lg border-[1px] border-gray-secondary bg-white-light px-4 py-[0.65rem] text-left font-light text-black outline-none`}
      >
        <span className={`${projects.find(project => project.id == connexionProjectId) ? 'text-black' : 'text-gray-primary'}`}>
          {projects.find(project => project.id == connexionProjectId)?.name || 'Aucun projet sélectionné'}
        </span>
        <Icons.ArrowRight color='#57CC99' className='translate-y-[2px] rotate-90' />
      </MenuButton>
      <MenuItems
        className={`absolute z-20 flex max-h-[134px] overflow-y-auto ${fullWitdth ? 'w-full' : 'w-full min-xs:w-[350px]'} flex-col items-center justify-start rounded-lg bg-white shadow-md scrollbar scrollbar-track-transparent scrollbar-thumb-gray-tertiary scrollbar-thumb-rounded scrollbar-w-1`}
      >
        {projects.map((project, i) => (
          <MenuItem
            key={i}
            as={'button'}
            onClick={() => {
              setConnexionProjectId && setConnexionProjectId(project.id);
              editedApp && setEditedApp && setEditedApp({ ...editedApp, skeziaProjectId: project.id });
            }}
            className='w-full rounded-lg px-4 py-[0.65rem] text-left hover:bg-green-primary hover:bg-opacity-10'
          >
            {project.name}
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
};

export default ProjectDropdown;
