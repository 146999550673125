import React, { FC, SVGProps } from 'react';

export const FranceConnectWhat: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={17} height={17} viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Ic&#195;&#180;ne'>
        <path
          id='Ic&#195;&#180;ne_2'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M7.16667 4.5V5.83333H3.83333V13.1667H11.1667V9.83333H12.5V13.8333C12.5 14.2015 12.2015 14.5 11.8333 14.5H3.16667C2.79848 14.5 2.5 14.2015 2.5 13.8333V5.16667C2.5 4.79848 2.79848 4.5 3.16667 4.5H7.16667ZM14.5 2.5V7.83333H13.1667V4.77533L7.97133 9.97133L7.02867 9.02867L12.2233 3.83333H9.16667V2.5H14.5Z'
          fill='#000091'
        />
      </g>
    </svg>
  );
};
