import React, { FC, SVGProps } from 'react';

export const Info: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={16} height={17} viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Info'>
        <path
          id='Subtract'
          opacity={0.5}
          fillRule='evenodd'
          clipRule='evenodd'
          d='M16 8.38989C16 12.8082 12.4183 16.3899 8 16.3899C3.58172 16.3899 0 12.8082 0 8.38989C0 3.97161 3.58172 0.389893 8 0.389893C12.4183 0.389893 16 3.97161 16 8.38989ZM8.88871 4.83435C8.88871 5.32527 8.49075 5.72324 7.99983 5.72324C7.50891 5.72324 7.11094 5.32527 7.11094 4.83435C7.11094 4.34343 7.50891 3.94546 7.99983 3.94546C8.49075 3.94546 8.88871 4.34343 8.88871 4.83435ZM8.8 12.8343V7.501H7.2V12.8343H8.8Z'
          fill='#57CC99'
        />
      </g>
    </svg>
  );
};
