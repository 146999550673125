import { Transition } from '@headlessui/react';
import { AxiosError } from 'axios';
import React, { useState } from 'react';
// import { useScreenshot } from 'use-react-screenshot';
import UsersService from '../../services/users.service';
import * as Icon from '../icons';
import { BasicButton } from './Button';

export const SupportButton = () => {
  // const takeImage = useScreenshot()[1];

  const [showContactLabel, setShowContactLabel] = useState<boolean>(false);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  // const [imageUrl, setImageUrl] = useState<string>('');
  // const [loadingScreenshot, setLoadingScreenshot] = useState<boolean>(false);
  // const [disabled, setDisabled] = useState<boolean>(false);
  const INITIAL_SUPPORTFIELDS = {
    name: {
      value: '',
      errors: [],
      validationRules: [],
    },
    email: {
      value: '',
      errors: [],
      validationRules: [],
    },
    description: {
      value: '',
      errors: [],
      validationRules: [],
    },
  };
  const [supportFields, setSupportFields] = useState(INITIAL_SUPPORTFIELDS);
  const [messageSend, setMessageSend] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  type AxiosErrorDatas = {
    error: string;
    message: string;
    statusCode: number;
  };

  const usersService = new UsersService();

  // const takeScreenshot = async () => {
  //     setLoadingScreenshot(true);

  //     // const imageUrl = await takeImage(document.body);
  //     setImageUrl(imageUrl);

  //     setLoadingScreenshot(false);
  // };

  const sendSupportMessage = async () => {
    usersService
      .sendSupportMessage(supportFields.name.value, supportFields.email.value, supportFields.description.value)
      .then(() => {
        setMessageSend(true);
        setSupportFields(INITIAL_SUPPORTFIELDS);
      })
      .catch(error => {
        const errData = (error as AxiosError).response?.data as AxiosErrorDatas;
        setErrorMessage(errData.message);
      });
  };

  return (
    <>
      {!showContactForm && (
        <button
          className={`fixed bottom-5 right-4 z-30 flex h-14 items-center justify-center space-x-2 overflow-hidden rounded-3xl rounded-tr-[4px] bg-green-dark text-sm font-semibold text-white hover:bg-green-secondary ${showContactLabel ? 'w-32' : 'w-16'} `}
          onClick={() => setShowContactForm(true)}
          onMouseEnter={() => setShowContactLabel(true)}
          onMouseLeave={() => setShowContactLabel(false)}
        >
          <div>
            <Icon.Contact color='#fff' />
          </div>
          {showContactLabel && <span className='font-bold'>Contact</span>}
        </button>
      )}

      <Transition show={showContactForm}>
        <div
          className='fixed bottom-5 right-4 z-40 max-h-[70%] w-[90%] max-w-[400px] overflow-x-hidden rounded-2xl'
          data-html2canvas-ignore
        >
          <div className='sticky top-0 z-50 bg-green-primary px-5 py-3 text-white'>
            <div className='mb-3 flex items-center justify-between'>
              <h4 className='text-xl font-semibold text-white'>Contact</h4>
              <Icon.Cross
                color='#fff'
                className='cursor-pointer'
                onClick={() => {
                  setShowContactForm(false);
                  setMessageSend(false);
                }}
              />
            </div>
            <p>Nous sommes là pour répondre à vos questions.</p>
          </div>

          {!messageSend && !errorMessage && (
            <form
              className='flex flex-col justify-center bg-white p-4'
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                sendSupportMessage();
              }}
            >
              <label className='mb-2 inline-block text-gray-primary'>Comment doit-on vous appeler ?</label>
              <input
                type='text'
                placeholder='Comment doit-on vous appeler ?'
                className={`w-full rounded-lg border border-gray-tertiary bg-white px-4 py-[10px] text-sm font-semibold text-black outline-none placeholder:text-gray-primary focus:border-green-primary focus:ring-2 focus:ring-green-primary`}
                name='nickname'
                value={supportFields.name.value}
                onChange={e =>
                  setSupportFields({
                    ...supportFields,
                    name: {
                      ...supportFields.name,
                      value: e.target.value,
                    },
                  })
                }
              />

              <div className='mt-2' />

              <label className='mb-2 inline-block text-gray-primary'>Email</label>
              <input
                type='text'
                placeholder='Email'
                className={`w-full rounded-lg border border-gray-tertiary bg-white px-4 py-[10px] text-sm font-semibold text-black outline-none placeholder:text-gray-primary focus:border-green-primary focus:ring-2 focus:ring-green-primary`}
                name='nickname'
                value={supportFields.email.value}
                onChange={e =>
                  setSupportFields({
                    ...supportFields,
                    email: {
                      ...supportFields.email,
                      value: e.target.value,
                    },
                  })
                }
              />

              <div className='mt-2' />

              <label className='mb-2 inline-block text-gray-primary'>Description</label>
              <textarea
                rows={6}
                className={`w-full resize-none rounded-lg border border-gray-tertiary px-4 py-[10px] text-sm font-semibold outline-none placeholder:text-gray-primary focus:border-green-primary focus:ring-2 focus:ring-green-primary`}
                placeholder='Description'
                name='description'
                value={supportFields.description.value}
                onChange={e =>
                  setSupportFields({
                    ...supportFields,
                    description: {
                      ...supportFields.description,
                      value: e.target.value,
                    },
                  })
                }
              />

              {/* <div className='mt-2'/>

                            <label className="text-gray-primary mb-2 inline-block ">Pièces jointes</label>
                            <div className="px-3 py-4 text-sm text-center border-2 border-dotted rounded-lg bg-green-light-secondary dotte border-green-primary text-gray-primary">
                                {imageUrl
                                    ? <>
                                        <div>Une image a été uploadé.</div>
                                    </>
                                    : <>
                                        {loadingScreenshot
                                            ? <div>Chargement...</div>
                                            : <>
                                                <div>Ajoutez une capture d'écran de votre fenêtre pour nous permettre de répondre au mieux à votre problème.</div>
                                                <button
                                                    className="rounded-lg text-sm font-semibold text-gray-primary py-2.5 px-6 transition-[background-color] duration-300 bg-green-light-secondary #000 disabled:opacity-50  flex items-center justify-center mx-auto mt-3 space-x-3 bg-white hover:bg-green-light-primary border-[1px] border-green-primary group"
                                                    disabled={loadingScreenshot || disabled}
                                                    onClick={() => takeScreenshot()}
                                                >
                                                    <Icon.Gallery />
                                                    <span>Capturer mon écran</span>
                                                </button>
                                            </>
                                        }
                                    </>
                                }
                            </div> */}
              <BasicButton label='Envoyer' fullWidth={false} isSubmitButton={true} />
            </form>
          )}
          {messageSend && !errorMessage && <div className='py-2 text-center'>Votre message a bien été envoyé.</div>}

          {errorMessage && <div className='py-2 text-center'>Veuillez nous excuser, une erreur s&apos;est produite : {errorMessage}</div>}
        </div>
      </Transition>
    </>
  );
};
