import React, { FC, SVGProps } from 'react';

export const Right: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={25} height={25} viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Text/Save'>
        <path
          id='Subtract'
          fillRule='evenodd'
          clipRule='evenodd'
          d='M8 14C11.3137 14 14 11.3137 14 8C14 6.77773 13.6345 5.64083 13.0069 4.6926L8.23669 9.99284C7.77491 10.5059 6.99786 10.5817 6.44563 10.1676L4.26667 8.53333C3.97212 8.31242 3.91242 7.89455 4.13333 7.6C4.35425 7.30545 4.77212 7.24575 5.06667 7.46667L7.24563 9.10089L12.1427 3.6597C11.0657 2.63145 9.6066 2 8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14Z'
          fill='#57CC99'
        />
      </g>
    </svg>
  );
};
