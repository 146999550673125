import React, { FC, SVGProps } from 'react';

export const ArrowCircleLeft: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Back button'>
        <circle id='Ellipse 2' cx={12} cy={12} r={11.5} fill='#EBF9F3' stroke='#57CC99' />
        <path id='Vector 7' d='M14 6L8 12L14 18' stroke='#57CC99' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
      </g>
    </svg>
  );
};

export default ArrowCircleLeft;
