import React, { FC, SVGProps } from 'react';

interface CheckboxCheckedProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const CheckboxChecked: FC<CheckboxCheckedProps> = ({ color = '#57CC99', ...props }) => {
  return (
    <svg width={24} height={25} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect y={0.5} width={20} height={20} rx={2} fill={color} />
      <path d='M14.6654 7L8.2487 13.4167L5.33203 10.5' stroke='white' strokeWidth={1.5} strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};
