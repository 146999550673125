import * as Yup from 'yup';

export const subscriptionSchema = Yup.object({
  email: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Le format attendu est de type email. Ex : azerty@skezi.eu',
    ),
  nickname: Yup.string().default('').required('Ce champ est obligatoire').min(8, 'Votre identifiant doit avoir un minimum de 8 caractères'),
  password: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.{12,})/,
      "Votre mot de passe doit contenir 12 caractères minimum, ainsi qu'au moins 1 de chacun de ces éléments : lettre minuscule, lettre majuscule, chiffre, caractère spécial.",
    ),
  confirmPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .oneOf([Yup.ref('password')], 'Les mots de passe ne correspondent pas'),
  acceptCGU: Yup.boolean().default(false).oneOf([true], 'Veuillez accepter les CGU.'),
  subscribeNewsletter: Yup.boolean().default(false),
});
export type subscriptionType = Yup.InferType<typeof subscriptionSchema>;
