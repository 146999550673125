import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import * as Icons from '../../../components/icons';
import { EditedApp } from '../../../services/apiConnections/types';

type PermissionDropdownProps = {
  type: string;
  scopes: string[];
  editedApp: EditedApp;
  setEditedApp: React.Dispatch<React.SetStateAction<EditedApp | undefined>>;
};

const PermissionDropdown = ({ type, scopes, editedApp, setEditedApp }: PermissionDropdownProps) => {
  const filteredScopes = scopes?.filter(scope => scope.startsWith(type));

  const getLabel = (scope: string) => {
    switch (scope?.split('-').pop()) {
      case 'write':
        return { name: 'Modification', color: '#55C080' };
      case 'read':
        return { name: 'Visualisation', color: '#A3AAED' };
      default:
        return { name: '', color: '' };
      // Si on veux ajouter une permission autre que 'write' ou 'read', on l'ajoute ici (avec nom et couleur)
    }
  };

  const getCurrentScope = () => {
    return editedApp?.scopes?.find(scope => scope.includes(type)) || null;
  };

  const addPermission = (scope: string) => {
    if (editedApp?.scopes?.some(scope => scope.includes(type))) {
      setEditedApp({ ...editedApp, scopes: [...editedApp.scopes.filter(scope => !scope.includes(type)), scope] });
    } else {
      setEditedApp({ ...editedApp, scopes: [...editedApp.scopes, scope] });
    }
  };

  const removePermission = () => {
    if (editedApp?.scopes?.some(scope => scope.includes(type))) {
      setEditedApp({ ...editedApp, scopes: [...editedApp.scopes.filter(scope => !scope.includes(type))] });
    }
  };

  return (
    <Menu as={'div'} className='relative'>
      <MenuButton
        as={'div'}
        className='mb-1 flex w-[150px] cursor-pointer flex-row items-center justify-between rounded-md border-[1px] border-gray-secondary bg-white-light px-3 py-[0.5rem] text-left text-sm font-light text-black outline-none min-xs:w-[170px]'
      >
        <div className='flex flex-row items-center justify-start gap-2'>
          <div className='h-2 w-2 rounded-full' style={{ backgroundColor: getLabel(getCurrentScope() as string).color || '#F8A185' }}></div>
          <span className=''>{getLabel(getCurrentScope() as string).name || 'Aucun accès'}</span>
        </div>
        <Icons.ArrowRight color='#57CC99' className='translate-y-[1px] rotate-90 scale-[0.85]' />
      </MenuButton>
      <MenuItems className='absolute z-20 flex w-[150px] flex-col items-center justify-start rounded-md bg-white text-sm shadow-md min-xs:w-[170px]'>
        {scopes &&
          filteredScopes?.map((scope, i) => (
            <MenuItem
              key={i}
              as={'button'}
              onClick={() => addPermission(scope)}
              className='flex w-full flex-row items-center justify-start gap-2 rounded-lg px-3 py-[0.5rem] text-left hover:bg-green-primary hover:bg-opacity-10'
            >
              <div className='h-2 w-2 rounded-full' style={{ backgroundColor: getLabel(scope).color }}></div>
              <span>{getLabel(scope).name}</span>
            </MenuItem>
          ))}
        <MenuItem
          as={'button'}
          onClick={() => removePermission()}
          className='flex w-full flex-row items-center justify-start gap-2 rounded-lg px-3 py-[0.5rem] text-left hover:bg-green-primary hover:bg-opacity-10'
        >
          <div className='h-2 w-2 rounded-full' style={{ backgroundColor: '#F8A185' }}></div>
          <span>Aucun accès</span>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
};

export default PermissionDropdown;
