import React, { FC, SVGProps } from 'react';

interface CheckboxUncheckedProps extends SVGProps<SVGSVGElement> {
  borderColor?: string;
}

export const CheckboxUnchecked: FC<CheckboxUncheckedProps> = ({ borderColor = '#DCE2EC', ...props }) => {
  return (
    <svg width={24} height={25} viewBox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <rect x={0.75} y={1.13989} width={18.5} height={18.5} rx={1.25} fill='#F6F8FF' stroke={borderColor} strokeWidth={1.5} />
    </svg>
  );
};
