import React, { FC, SVGProps } from 'react';

interface ErrorProps extends SVGProps<SVGSVGElement> {
  color?: string;
}

export const Error: FC<ErrorProps> = ({ color = '#D82F2F', ...props }) => {
  return (
    <svg width={18} height={17} viewBox='0 0 18 17' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M17.758 13.4123L11.2554 1.43528C11.0832 1.11897 10.829 0.854932 10.5194 0.670961C10.2098 0.486989 9.85635 0.389893 9.49623 0.389893C9.13611 0.389893 8.78264 0.486989 8.47306 0.670961C8.16347 0.854932 7.90922 1.11897 7.73706 1.43528L1.22782 13.4123C1.05744 13.7181 0.9702 14.0632 0.974781 14.4133C0.979361 14.7633 1.07561 15.106 1.25392 15.4073C1.43224 15.7085 1.6864 15.9578 1.99106 16.1302C2.29573 16.3026 2.64026 16.3921 2.99032 16.3899H15.9955C16.3427 16.3902 16.684 16.3002 16.9859 16.1289C17.2879 15.9575 17.5401 15.7106 17.7178 15.4123C17.8955 15.1141 17.9927 14.7747 17.9997 14.4276C18.0068 14.0805 17.9235 13.7375 17.758 13.4323V13.4123ZM8.49814 4.86682C8.49814 4.60123 8.60365 4.34651 8.79145 4.15871C8.97926 3.9709 9.23397 3.8654 9.49957 3.8654C9.76516 3.8654 10.0199 3.9709 10.2077 4.15871C10.3955 4.34651 10.501 4.60123 10.501 4.86682V9.46669C10.501 9.73228 10.3955 9.987 10.2077 10.1748C10.0199 10.3626 9.76516 10.4681 9.49957 10.4681C9.23397 10.4681 8.97926 10.3626 8.79145 10.1748C8.60365 9.987 8.49814 9.73228 8.49814 9.46669V4.86682ZM9.52627 13.8863C9.29916 13.8863 9.07715 13.8189 8.88831 13.6928C8.69947 13.5666 8.55229 13.3873 8.46538 13.1774C8.37847 12.9676 8.35573 12.7367 8.40004 12.514C8.44434 12.2912 8.55371 12.0866 8.7143 11.926C8.8749 11.7654 9.0795 11.6561 9.30225 11.6118C9.525 11.5675 9.75588 11.5902 9.96571 11.6771C10.1755 11.764 10.3549 11.9112 10.481 12.1C10.6072 12.2889 10.6746 12.5109 10.6746 12.738C10.6746 13.0425 10.5536 13.3346 10.3382 13.55C10.1229 13.7653 9.83082 13.8863 9.52627 13.8863Z'
        fill={color}
      />
    </svg>
  );
};
