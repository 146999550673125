import { useMatomo } from '@datapunt/matomo-tracker-react';
import { FC, useEffect } from 'react';

type SeoProps = {
  title?: string;
};

export const Seo: FC<SeoProps> = ({ title }) => {
  const { trackPageView } = useMatomo();
  const titleOnly = /\.[^.]*[.|:]/g.test(window.location.host);
  const metaTitle = title ? (titleOnly ? title : `${title} - SKEZI`) : 'SKEZI';

  useEffect(() => {
    trackPageView({
      documentTitle: metaTitle,
    });
    document.title = metaTitle;
  }, [location.pathname]);

  return null;
};
