import * as Yup from 'yup';

export const requestSchema = Yup.object({
  email: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      'Le format attendu est de type email. Ex : azerty@skezi.eu',
    ),
});
export type requestType = Yup.InferType<typeof requestSchema>;

export const resetPasswordSchema = Yup.object({
  newPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W)(?=.{12,})/,
      "Votre mot de passe doit contenir 12 caractères minimum, ainsi qu'au moins 1 de chacun de ces éléments : lettre minuscule, lettre majuscule, chiffre, caractère spécial.",
    ),
  confirmNewPassword: Yup.string()
    .default('')
    .required('Ce champ est obligatoire')
    .oneOf([Yup.ref('newPassword')], 'Les mots de passe ne correspondent pas'),
});
export type resetPasswordType = Yup.InferType<typeof resetPasswordSchema>;
