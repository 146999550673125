import { useRef, useState } from 'react';
import { Seo } from '../../components/common/Seo';
import { ToastFunc, ToastQueue } from '../../components/common/Toast';
import ConfirmationModal from './ConfirmationModal';
import ErrorForm from '../../components/forms/ErrorForm';
import SubscriptionForm from './forms/SubscriptionForm';

const SubscriptionComponent = () => {
  const toastQueueRef = useRef<ToastFunc>();
  const [hasError, setHasError] = useState(false);
  const [modaleCreatedAccountOpen, setModaleCreatedAccountOpen] = useState(false);

  return (
    <>
      <ToastQueue ref={toastQueueRef} />
      <div className={`z-[10] w-full rounded-lg bg-white p-6`}>
        {hasError ? (
          <ErrorForm onClick={() => setHasError(false)} />
        ) : (
          <SubscriptionForm
            setHasError={setHasError}
            setModaleCreatedAccountOpen={setModaleCreatedAccountOpen}
            toastQueueRef={toastQueueRef}
          />
        )}
      </div>
      <ConfirmationModal modaleCreatedAccountOpen={modaleCreatedAccountOpen} setModaleCreatedAccountOpen={setModaleCreatedAccountOpen} />
      {/* <Modal
                    modalOpen={modaleCguOpen}
                    setModalOpenToFalse={() => setModaleCguOpen(false)}
                    modalWidth='w-[70%]'
                    >
                    <div className='py-5 text-2xl font-bold text-center text-green-dark'>
                        Mentions légales & CGU
                    </div>
                    <div className='p-5 mt-2 rounded-2xl bg-white-light'>
                        <div className='pb-5 text-lg font-bold text-green-dark'>
                        1. Présentation du site internet
                        </div>
                        <div className='text-sm'>
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book. It has survived not
                        only five centuries, but also the leap into electronic typesetting,
                        remaining essentially unchanged.
                        </div>
                    </div>
                    <InputCheckbox
                        label={
                          <span>
                              J’ai pris connaissance et accepte les Conditions Générales
                              d’Utilisation
                          </span>
                        }
                        checked={subscriptionFields.acceptCGU.value}
                        actionOnClick={() =>
                        setSubscriptionFields({
                            ...subscriptionFields,
                            acceptCGU: {
                            ...subscriptionFields.acceptCGU,
                            value: !subscriptionFields.acceptCGU.value,
                            },
                        })
                        }
                    />

                    <div className='flex justify-center mt-4'>
                        <BasicButton
                        label='Fermer'
                        fullWidth={false}
                        onClick={() => setModaleCguOpen(false)}
                        />
                    </div>
                </Modal> */}
      <Seo title={'Inscription'} />
    </>
  );
};

export default SubscriptionComponent;
