import { request } from '../fetcher';
import { AppCreated, Application, EditedApp, Project, Scopes } from './types';

export default class ApiConnectionsService {
  async createApplication(name: string, projectId: string): Promise<AppCreated> {
    return await request({
      method: 'post',
      url: `/applications/create`,
      data: { name, skeziaProjectId: projectId, scopes: [] },
    });
  }

  async fetchApplications(): Promise<Application[]> {
    return await request({
      method: 'get',
      url: `/applications`,
    });
  }

  async deleteApplication(appId: string): Promise<Application[]> {
    return await request({
      method: 'delete',
      url: `/applications/${appId}/delete`,
    });
  }

  async fetchProjects(): Promise<Project[]> {
    return await request({
      method: 'get',
      url: `${import.meta.env.VITE_SKEZIA_BACK_URL}/projects`,
      skeziaAPI: true,
    });
  }

  async updateSecret(appId: string): Promise<{ appSecret: string }> {
    return await request({
      method: 'patch',
      url: `/applications/${appId}/update-secret`,
    });
  }

  async updateApplication(appId: string, editedApp: EditedApp): Promise<{ appId: string; name: string }> {
    return await request({
      method: 'patch',
      url: `/applications/${appId}/update`,
      data: editedApp,
    });
  }

  async fetchScopes(): Promise<Scopes> {
    return await request({
      method: 'get',
      url: `/applications/scopes`,
    });
  }
}
