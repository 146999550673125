import { Dialog, Transition } from '@headlessui/react';
import { FC, Fragment, ReactNode } from 'react';
import * as Icon from '../icons';

interface ModalProps {
  modalOpen: boolean;
  setModalOpenToFalse: () => void;
  modalWidth?: string;
  children: ReactNode;
}

export const Modal: FC<ModalProps> = ({ modalOpen, setModalOpenToFalse, children, modalWidth }) => {
  return (
    <>
      <Transition appear show={modalOpen} as={Fragment}>
        <Dialog as='div' className='relative z-10' onClose={setModalOpenToFalse}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black/25' />
          </Transition.Child>

          <div className='fixed inset-0 h-full w-screen overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={`${modalWidth && modalWidth} transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all`}
                >
                  <div className='flex justify-end'>
                    <Icon.Cross onClick={setModalOpenToFalse} className='cursor-pointer' />
                  </div>
                  {children}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
