import { FC, SVGProps } from 'react';
interface CopyProps extends SVGProps<SVGSVGElement> {
  isActive?: boolean;
  isHovered?: boolean;
  insideMoreOptions?: boolean;
}

export const Copy: FC<CopyProps> = ({ isActive, isHovered = false, insideMoreOptions = false, color = '#57CC99', ...props }) => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill={`${color}`} xmlns='http://www.w3.org/2000/svg' {...props}>
      <circle
        className={
          isHovered ? (insideMoreOptions ? `opacity-0 group-hover/moreOptions:opacity-100` : 'opacity-0 group-hover:opacity-100') : ''
        }
        cx='6'
        cy='18'
        r='6'
        fill='#FEC592'
        opacity={isHovered ? undefined : isActive ? 1 : 0}
      />
      <path
        d='M12.3633 7.25V3H8.65356C7.42427 3 6.42773 3.81548 6.42773 4.82143V15.75C6.42773 16.7559 7.42427 17.5714 8.65355 17.5714H17.559C18.7883 17.5714 19.7849 16.7559 19.7849 15.75V9.07143H14.589C13.3598 9.07143 12.3633 8.25595 12.3633 7.25Z'
        fill={`${color}`}
      />
      <path
        d='M13.7125 7.25L13.7143 3L19.4803 7.85714H14.3196C13.9843 7.85714 13.7125 7.58531 13.7125 7.25ZM4 5.42857C4 4.75795 4.54366 4.21429 5.21429 4.21429V15.75C5.21429 17.4266 6.57342 18.7857 8.25 18.7857H17.3571C17.3571 19.4564 16.8135 20 16.1429 20H8.31747C5.933 20 4 18.067 4 15.6825V5.42857Z'
        fill={`${color}`}
      />
    </svg>
  );
};
