import React, { useMemo, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { BasicButton } from '../../components/common/Button';
import { Modal } from '../../components/common/Modal';
import ApiConnectionsService from '../../services/apiConnections/apiConnections.service';
import { Application, Project } from '../../services/apiConnections/types';
import ProjectDropdown from './dropdowns/ProjectDropdown';

type NewConnectionModalProps = {
  isNewConnectionModalOpen: boolean;
  setIsNewConnectionModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  applications: Application[];
  setApplications: React.Dispatch<React.SetStateAction<Application[]>>;
};

const NewConnectionModal = ({
  isNewConnectionModalOpen,
  setIsNewConnectionModalOpen,
  applications,
  setApplications,
}: NewConnectionModalProps) => {
  const apiConnectionsService = new ApiConnectionsService();
  const navigate: NavigateFunction = useNavigate();

  const [connexionName, setConnexionName] = useState<string>('');
  const [connexionProjectId, setConnexionProjectId] = useState<string>('');
  const createNewConnexion = async (name: string, projectId: string) => {
    if (!name || !projectId) return;
    const newApp = await apiConnectionsService.createApplication(name, projectId);
    setConnexionName('');
    setConnexionProjectId('');
    setIsNewConnectionModalOpen(false);
    setApplications([...applications, { appId: newApp.appId, name: newApp.appName, skeziaProjectId: newApp.skeziaProjectId, scopes: [] }]);
    navigate(`/dashboard/api-connections/${newApp.appId}`, { state: { appSecret: newApp.appSecret } });
  };

  const [projects, setProjects] = useState<Project[]>([]);
  useMemo(async () => {
    const projects = await apiConnectionsService.fetchProjects();
    setProjects(projects);
  }, []);

  return (
    <Modal
      modalOpen={isNewConnectionModalOpen}
      setModalOpenToFalse={() => setIsNewConnectionModalOpen(false)}
      modalWidth='w-[95%] min-sm:w-[80%] min-lg:w-[40%]'
    >
      <div className='-mt-2 flex flex-col items-center justify-center gap-8'>
        <h3 className='text-center text-xl font-semibold text-green-dark'>Nouvelle connexion API</h3>
        <div className='flex w-full flex-col items-center justify-start gap-3'>
          <label className='self-start font-semibold text-green-dark' htmlFor='name'>
            Nom de la connexion API
          </label>
          <input
            id='name'
            type='text'
            placeholder='Saisissez un nom'
            value={connexionName}
            onChange={e => setConnexionName(e.target.value)}
            className='w-full rounded-lg border-[1px] border-gray-secondary bg-white-light px-4 py-[0.65rem] font-light text-black outline-none'
          />
        </div>
        <div className='flex w-full flex-col gap-3'>
          <label className='mt-2 self-start font-semibold text-green-dark'>Sélectionner le projet à connecter</label>
          <ProjectDropdown
            fullWitdth
            projects={projects}
            connexionProjectId={connexionProjectId}
            setConnexionProjectId={setConnexionProjectId}
          />
        </div>
        <div className='mb-3 flex flex-row items-center justify-center gap-4'>
          <BasicButton
            className='!mt-4 py-3 font-normal'
            template='orangeOutline'
            label='Annuler'
            fullRounded
            onClick={() => setIsNewConnectionModalOpen(false)}
          />
          <BasicButton
            className='!mt-4 py-3 font-normal'
            template='orange'
            label='Sauvegarder'
            fullRounded
            onClick={() => createNewConnexion(connexionName, connexionProjectId)}
          />
        </div>
      </div>
    </Modal>
  );
};

export default NewConnectionModal;
