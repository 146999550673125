import React, { FC, SVGProps } from 'react';

export const Eye: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20.5405 12C20.5405 11.6412 20.3465 11.4056 19.9583 10.9343C18.5382 9.21014 15.406 6 11.7703 6C8.13454 6 5.00233 9.21014 3.58224 10.9343C3.19408 11.4056 3 11.6412 3 12C3 12.3588 3.19408 12.5944 3.58224 13.0657C5.00233 14.7899 8.13454 18 11.7703 18C15.406 18 18.5382 14.7899 19.9583 13.0657C20.3465 12.5944 20.5405 12.3588 20.5405 12ZM11.7703 15C13.4271 15 14.7703 13.6569 14.7703 12C14.7703 10.3431 13.4271 9 11.7703 9C10.1134 9 8.77027 10.3431 8.77027 12C8.77027 13.6569 10.1134 15 11.7703 15Z'
        fill='#57CC99'
      />
    </svg>
  );
};
