import { useEffect, useMemo, useState } from 'react';
import { NavigateFunction, useLocation, useNavigate, useParams } from 'react-router-dom';
import { BasicButton } from '../../components/common/Button';
import ClipboardCopy from '../../components/common/ClipboardCopy';
import * as Icons from '../../components/icons';
import ApiConnectionsService from '../../services/apiConnections/apiConnections.service';
import { Application, EditedApp, Project } from '../../services/apiConnections/types';
import PermissionDropdown from './dropdowns/PermissionDropdown';
import ProjectDropdown from './dropdowns/ProjectDropdown';

const ConnectionEdit = () => {
  const params = useParams();
  const apiConnectionsService = new ApiConnectionsService();
  const navigate: NavigateFunction = useNavigate();
  const location = useLocation();

  const [projects, setProjects] = useState<Project[]>([]);
  const [scopes, setScopes] = useState<string[]>();
  const [currentApp, setCurrentApp] = useState<Application>();
  const [appSecret, setAppSecret] = useState<string | null>();
  const [editedApp, setEditedApp] = useState<EditedApp>();

  const permissionTypes = [
    { type: 'skezia:patient', name: 'Patients' },
    { type: 'skezia:answer', name: 'Réponses' },
  ];
  // Si on veux rajouter un nouveau type de permission, on le rajoute ici

  useMemo(async () => {
    const apps = await apiConnectionsService.fetchApplications();
    setCurrentApp(apps.find(app => app.appId == params.appId));
    const scopes = await apiConnectionsService.fetchScopes();
    setScopes(scopes.scopes);
    setEditedApp({
      name: apps.find(app => app.appId == params.appId)?.name || '',
      skeziaProjectId: apps.find(app => app.appId == params.appId)?.skeziaProjectId || '',
      scopes: apps.find(app => app.appId == params.appId)?.scopes || [],
    });
    const projects = await apiConnectionsService.fetchProjects();
    setProjects(projects);
  }, []);

  useEffect(() => {
    setAppSecret(location.state?.appSecret || null);
    navigate(location.pathname, { replace: true, state: {} });
  }, []);

  const updateAppSecret = async (appId: string) => {
    if (!currentApp) return;
    const newSecret = await apiConnectionsService.updateSecret(appId);
    setAppSecret(newSecret.appSecret);
  };

  const updateApp = async (appId: string, editedApp: EditedApp) => {
    if (!currentApp) return;
    await apiConnectionsService.updateApplication(appId, editedApp);
    navigate('/dashboard/api-connections');
  };

  return (
    currentApp &&
    scopes &&
    editedApp && (
      <>
        <button
          onClick={() => navigate('/dashboard/api-connections')}
          className='mb-8 ml-2 mt-4 flex flex-row items-center justify-start gap-2'
        >
          <Icons.ArrowCircleLeft />
          <span className='text-gray-primary'>Mes applications</span>
        </button>

        <div className='m-2 flex w-full flex-col items-center justify-start gap-5 rounded-2xl border-[1px] border-gray-tertiary bg-white p-8'>
          <h3 className='w-full text-lg font-semibold text-green-dark'>Configuration manuelle</h3>
          {/* <p className='w-full text-green-dark'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p> */}
          <div className='mt-2 w-full border-b-[1px] border-b-gray-tertiary'></div>

          {/* Name */}
          <div className='flex w-full flex-col items-center justify-start gap-3'>
            <label className='mt-4 self-start font-semibold text-green-dark' htmlFor='name'>
              Nom de la connexion API
            </label>
            <input
              id='name'
              type='text'
              value={editedApp?.name}
              onChange={e => setEditedApp({ ...editedApp, name: e.target.value })}
              className='mr-auto w-full rounded-lg border-[1px] border-gray-secondary bg-white-light px-4 py-[0.65rem] font-light text-black outline-none min-xs:w-[350px]'
            />
          </div>
          <div className='mt-2 w-full border-b-[1px] border-b-gray-tertiary'></div>

          {/* Client ID & Secret ID */}
          <div className='mt-2 flex w-full flex-col items-center justify-start gap-6 min-xs:flex-row'>
            <div className='flex h-[7rem] w-full flex-col items-start justify-between rounded-xl bg-[#21577A] bg-opacity-10 px-4 py-3 min-xs:h-[8rem] min-xs:w-[10rem]'>
              <div className='text-sm text-green-dark'>
                <p className='mb-2 font-semibold'>Client ID</p>
                <p className='hidden min-xs:inline'>{currentApp?.appId.slice(0, 13)}...</p>
                <p className='min-xs:hidden'>{currentApp?.appId.slice(0, 22)}...</p>
              </div>
              <div className='flex flex-row items-center justify-start gap-1'>
                <ClipboardCopy id='target-clipboard-copy' text={currentApp?.appId} showText={false} />
              </div>
            </div>

            <div className='flex h-[7rem] w-full flex-col items-start justify-between rounded-xl bg-[#21577A] bg-opacity-10 px-4 py-3 min-xs:h-[8rem] min-xs:w-[10rem]'>
              <div className='text-sm text-green-dark'>
                <p className='mb-2 font-semibold'>Secret ID</p>
                {appSecret ? (
                  <>
                    <p className='hidden min-xs:inline'>{appSecret.slice(0, 13)}...</p>
                    <p className='min-xs:hidden'>{appSecret.slice(0, 22)}...</p>
                  </>
                ) : (
                  <p>*********</p>
                )}
              </div>
              {appSecret ? (
                <div className='flex flex-row items-center justify-start gap-1'>
                  <ClipboardCopy id='target-clipboard-copy' text={appSecret} showText={false} />
                </div>
              ) : (
                <button
                  onClick={() => updateAppSecret(currentApp.appId)}
                  className='text-left text-sm text-green-dark underline-offset-2 hover:underline'
                >
                  Générer un nouveau code
                </button>
              )}
            </div>
          </div>
          <div className='mt-2 w-full border-b-[1px] border-b-gray-tertiary'></div>

          {/* Permissions */}
          <h3 className='mt-4 w-full text-lg font-semibold text-green-dark'>Permissions</h3>
          <div className='flex w-full flex-col items-center justify-start gap-3'>
            <label className='mt-2 self-start font-semibold text-green-dark'>Sélectionner le projet à connecter</label>
            <ProjectDropdown
              projects={projects}
              connexionProjectId={editedApp?.skeziaProjectId}
              editedApp={editedApp}
              setEditedApp={setEditedApp}
            />
            <div className='mt-2 flex flex-col items-center justify-start gap-3 self-start'>
              {permissionTypes.map((type, i) => (
                <div key={i} className='flex flex-row items-center justify-start gap-3 min-xs:gap-20'>
                  <p className='w-[110px] text-left font-semibold text-green-dark'>{type.name}</p>
                  <PermissionDropdown type={type.type} scopes={scopes} editedApp={editedApp} setEditedApp={setEditedApp} />
                </div>
              ))}
            </div>
          </div>

          {/* Save */}
          <div className='flex flex-row items-center justify-center gap-4 min-xs:mr-10 min-xs:self-end'>
            <BasicButton
              className='mt-0 py-3 text-sm font-normal'
              template='orangeOutline'
              label='Annuler'
              fullRounded
              onClick={() => navigate('/dashboard/api-connections')}
            />
            <BasicButton
              className='mt-0 py-3 text-sm font-normal'
              template='orange'
              label='Sauvegarder'
              fullRounded
              onClick={() => updateApp(currentApp.appId, editedApp as EditedApp)}
            />
          </div>
        </div>
      </>
    )
  );
};

export default ConnectionEdit;
