import React, { FC, SVGProps } from 'react';

export const LoginBackground: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width={1295}
      height={418}
      viewBox='0 0 1295 418'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      {...props}
    >
      <path
        d='M212.701 251.627C302.728 300.242 447.74 310.431 488.759 291.991C517.683 278.984 529.562 233.628 563.224 229.62C598.758 225.94 621.145 202.529 650.346 199.587C679.547 196.646 707.469 219.298 709.126 216.879'
        stroke='url(#paint0_linear_19923_92162)'
        strokeMiterlimit={10}
      />
      <path
        d='M213.652 263.824C306.739 290.894 413.577 295.322 487.05 289.685C523.035 279.67 539.047 233.874 569.721 219.882C595.27 206.885 613.492 185.822 636.421 183.034C644.35 182.07 651.748 181.906 658.92 182.593C688.234 185.33 708.206 208.566 709.096 216.889'
        stroke='url(#paint1_linear_19923_92162)'
        strokeMiterlimit={10}
      />
      <path
        d='M214.604 276.011C310.74 281.546 379.404 280.203 485.362 287.378C528.396 280.357 548.542 234.109 576.249 210.155C600.478 186.959 617.718 159.233 642.959 161.263C652.27 162.001 660.302 163.497 667.535 165.599C696.961 174.014 709.004 197.835 709.127 216.9'
        stroke='url(#paint2_linear_19923_92162)'
        strokeMiterlimit={10}
      />
      <path
        d='M215.556 288.209C314.73 272.198 345.231 265.095 483.664 285.072C533.768 281.034 558.037 234.355 582.767 200.418C605.696 167.013 621.862 132.881 649.497 139.482C660.169 142.034 668.855 145.088 676.14 148.594C705.668 162.688 709.782 187.113 709.127 216.9'
        stroke='url(#paint3_linear_19923_92162)'
        strokeMiterlimit={10}
      />
      <path
        d='M216.507 300.386C318.741 262.85 311.057 249.966 481.975 282.745C595.904 280.675 595.638 95.4682 656.024 117.7C716.421 139.933 710.907 166.326 709.126 216.889'
        stroke='url(#paint4_linear_19923_92162)'
        strokeMiterlimit={10}
      />
      <path
        d='M24.1533 331.207C26.4554 336.394 97.8002 386.649 139.218 394.942C180.635 403.234 218.707 398.17 218.707 398.17'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M25.4121 330.572C27.1412 334.467 90.5872 378.48 131.626 387.018C172.655 395.546 218.144 391.118 218.144 391.118'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M26.6704 329.916C27.8266 332.509 83.3942 370.29 124.014 379.054C164.653 387.808 217.551 384.046 217.551 384.046'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M27.9492 329.27C28.5222 330.572 76.2013 362.111 116.432 371.1C156.673 380.089 216.998 376.963 216.998 376.963'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M29.208 328.614C29.208 328.614 68.9884 353.921 108.84 363.136C148.682 372.351 216.436 369.88 216.436 369.88'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M34.4976 326.164C36.7894 320.988 107.95 270.866 149.275 262.584C190.601 254.302 228.56 259.365 228.56 259.365'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M35.7661 326.82C37.4952 322.936 98.6494 279.916 141.704 270.538C184.748 261.149 227.997 266.448 227.997 266.448'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M37.0249 327.466C38.1708 324.873 89.3492 288.967 134.123 278.461C178.896 267.965 227.424 273.49 227.424 273.49'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M38.2832 328.101C38.8562 326.8 80.0383 297.987 126.541 286.384C173.043 274.781 226.851 280.542 226.851 280.542'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M39.542 328.757C39.542 328.757 70.7381 307.038 118.97 294.328C167.201 281.608 226.289 287.614 226.289 287.614'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path d='M203.963 363.392C203.963 358.503 214.717 301.257 218.001 293.856' stroke='#FEAB61' strokeMiterlimit={10} />
      <path d='M219.945 354.249C218.615 346.685 187.358 293.446 210.532 284.713' stroke='#38A3A5' strokeMiterlimit={10} />
      <path
        d='M191.982 402.773C311.538 383.656 419.041 277.631 493.772 283.34C501.006 283.453 512.026 283.637 525.439 283.924C623.396 285.974 627.223 288.403 644.647 285.923C667.3 282.694 694.925 266.499 749.265 234.222C811.105 197.486 820.18 188.128 847.529 172.435C902.739 140.763 959.309 129.867 1072.45 108.055C1137.52 95.5092 1145.93 97.2722 1234.67 80.575C1286.79 70.7658 1329.21 61.6433 1356.88 55.4626'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M194.581 396.151C311.886 366.939 398.557 308.411 471.222 285.82C485.106 281.505 498.069 279.957 498.069 279.957C518.737 276.401 535.896 275.611 567.767 275.283C589.315 275.058 600.089 274.894 619.488 275.283C679.179 276.472 753.91 215.085 797.384 185.781C862.999 141.552 938.303 124.896 1088.92 91.5732C1097.09 89.7692 1153.19 80.8927 1265.25 62.5248C1285.57 59.1936 1322.16 53.2076 1342.05 49.9583'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M197.18 389.54C326.527 347.945 402.507 315.556 476.829 285.913C489.352 280.921 494.315 279.025 502.347 276.575C550.015 262.03 574.714 269.964 619.744 264.254C660.302 259.109 689.779 246.399 695.498 243.878C736.721 225.725 739.924 210.34 785.628 181.066C818.144 160.238 844.306 150.224 892.896 131.62C911.957 124.322 948.791 110.915 999.581 97.8462C1063.69 81.3438 1097.02 79.0068 1175.91 66.4403C1213.45 60.4646 1269.07 50.9321 1337.36 36.6027'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M199.799 382.918C276.29 356.996 323.209 339.128 363 331C363 331 417 318 452.859 300.208C453.759 299.399 482.2 286.446 490.355 281.382C490.355 281.382 498.059 276.606 506.664 273.192C526.544 265.29 591.003 257.992 631.244 254.865C658.613 252.744 697.268 242.043 742.891 202.017C749.275 196.41 754.606 193.335 756.847 191.592C785.362 169.473 867.184 116.194 1015.26 81.6205C1047.94 73.9843 1025 81.0157 1186.62 52.8283C1236.82 44.0749 1318.55 29.5404 1318.55 29.5404'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M202.398 376.297C294.144 343.886 346.643 333.79 378.483 331.238C394.189 329.977 427.012 328.788 458.126 308.247C468.061 301.687 470.885 297.741 486.099 287.993C491.747 284.375 514.359 270.189 543.457 260.093C574.94 249.177 583.585 253.635 616.603 246.481C660.864 236.897 692.644 218.642 725.272 199.895C757.072 181.629 758.064 176.72 791.102 155.933C813.08 142.106 833.164 131.2 851.121 122.415C851.121 122.415 874.981 110.73 900.028 100.972C973.848 72.2315 1083.15 58.5786 1083.15 58.5786C1138.73 51.6291 1217.7 39.2779 1320.08 15.3954'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <path
        d='M204.997 369.675C245.278 350.508 279.339 340.412 308.714 334.959C372.764 323.089 414.959 345.649 458.985 315.966C473.207 306.382 470.526 303.297 493.691 286.179C506.357 276.821 531.854 259.509 573.763 243.345C616.337 226.934 628.226 230.071 661.54 219.267C708.421 204.067 730.572 183.044 767.078 158.557C860.145 96.114 954.326 73.1335 1003.23 61.2026C1057.49 47.9596 1056.86 54.7041 1155.8 35.5571C1220.91 22.9497 1273.83 9.83998 1309.14 0.492004'
        stroke='#38A3A5'
        strokeMiterlimit={10}
      />
      <g filter='url(#filter0_d_19923_92162)'>
        <path
          d='M208.148 308.996C225.971 308.996 240.428 294.523 240.428 276.667C240.428 258.812 225.981 244.339 208.148 244.339C190.314 244.339 175.867 258.812 175.867 276.667C175.867 294.523 190.314 308.996 208.148 308.996Z'
          fill='white'
        />
      </g>
      <g filter='url(#filter1_d_19923_92162)'>
        <path
          d='M208.148 408C225.971 408 240.428 393.527 240.428 375.672C240.428 357.816 225.981 343.343 208.148 343.343C190.314 343.343 175.867 357.816 175.867 375.672C175.867 393.527 190.314 408 208.148 408Z'
          fill='white'
        />
      </g>
      <g filter='url(#filter2_d_19923_92162)'>
        <path
          d='M37.2806 358.657C55.104 358.657 69.5612 344.184 69.5612 326.328C69.5612 308.473 55.1142 294 37.2806 294C19.447 294 5 308.473 5 326.328C5 344.184 19.447 358.657 37.2806 358.657Z'
          fill='white'
        />
      </g>
      <rect x={24} y={312} width={26} height={29} fill='url(#pattern0)' />
      <path
        d='M211.473 361.209H193.895C192.77 361.209 191.9 361.465 191.307 361.978C190.713 362.49 190.406 363.187 190.406 364.069C190.406 364.95 190.693 365.606 191.266 366.129C191.839 366.652 192.719 366.908 193.885 366.908H201.426V391.733C201.426 393.148 201.743 394.204 202.387 394.901C203.032 395.598 203.861 395.956 204.894 395.956C205.928 395.956 206.715 395.608 207.36 394.911C208.005 394.224 208.312 393.158 208.312 391.733V366.908H211.463V361.199L211.473 361.209Z'
        fill='#214152'
      />
      <path
        d='M219.966 360.297C220.139 358.38 221.828 357.898 222.759 357.949L226.708 357.97C228.13 357.97 229.399 358.913 229.399 360.297V364.694C229.389 366.078 228.151 366.959 226.718 366.959L226.125 366.98V367.369L226.104 369.091L224.662 367.656L224.007 366.959H223.721L222.554 366.99C221.971 367.01 221.388 366.846 220.938 366.488C220.191 365.903 219.894 364.909 219.935 363.823L219.976 360.276H219.966V360.297Z'
        fill='#3BC3A8'
      />
      <path
        d='M222.227 367.01C222.104 367.01 222.002 366.99 221.91 366.959C221.797 366.949 221.695 366.918 221.613 366.857C221.459 366.785 221.337 366.693 221.245 366.57C221.204 366.498 221.152 366.436 221.122 366.365C220.886 366.047 220.876 365.565 221.122 365.247C221.081 364.489 221.245 363.782 221.275 363.023C221.296 362.5 221.378 361.947 221.326 361.434C221.326 361.363 221.316 361.301 221.306 361.25C221.173 361.188 221.06 361.065 220.978 360.891C220.958 360.86 220.948 360.809 220.927 360.778C220.927 360.399 220.948 360.01 220.978 359.641C220.978 359.6 220.989 359.548 220.999 359.507C220.927 359.487 220.835 359.477 220.753 359.466C220.753 359.466 220.753 359.446 220.764 359.436H219.188L215.822 359.456C214.389 359.456 213.131 360.399 213.131 361.783V366.18C213.141 367.564 214.379 368.445 215.812 368.445L216.405 368.466V368.855L216.425 370.577L217.868 369.142L218.523 368.445H218.809L220.446 368.425C221.378 368.24 221.971 367.718 222.298 367.01H222.217H222.227Z'
        fill='#214152'
      />
      <path
        d='M223.332 362.09H221.756V360.481C221.756 359.907 221.285 359.425 220.723 359.425C220.16 359.425 219.689 359.897 219.689 360.481V362.09H218.113C217.551 362.09 217.08 362.562 217.08 363.146C217.08 363.73 217.54 364.202 218.113 364.202H219.689V365.811C219.689 366.385 220.15 366.867 220.723 366.867C221.295 366.867 221.756 366.406 221.756 365.811V364.202H223.332C223.894 364.202 224.365 363.741 224.365 363.146C224.365 362.552 223.905 362.09 223.332 362.09Z'
        fill='white'
      />
      <path
        d='M216.507 270.497C213.151 269.984 210.583 267.083 210.583 263.588C210.583 261.999 211.115 260.534 212.015 259.365C211.944 259.252 211.872 259.129 211.79 259.017C211.401 258.443 210.89 257.961 210.276 257.602C209.642 257.233 208.874 257.049 207.933 257.049C206.992 257.049 206.245 257.233 205.61 257.602C204.976 257.971 204.475 258.443 204.096 259.047C203.717 259.642 203.339 260.39 203.012 261.272C202.664 262.174 202.377 262.932 202.132 263.578L193.619 285.851C193.271 286.743 193.025 287.44 192.862 287.932C192.708 288.434 192.626 288.916 192.626 289.387C192.626 290.197 192.944 290.925 193.598 291.56C194.253 292.206 194.99 292.514 195.849 292.514C196.842 292.514 197.568 292.216 198.008 291.612C198.448 291.017 198.98 289.849 199.604 288.137L201.201 283.74H214.727L216.323 288.045C216.538 288.578 216.783 289.182 217.09 289.879C217.387 290.576 217.663 291.089 217.919 291.417C218.175 291.745 218.492 292.011 218.87 292.216C219.249 292.411 219.699 292.503 220.231 292.503C221.142 292.503 221.909 292.165 222.554 291.509C223.198 290.843 223.516 290.115 223.516 289.326C223.516 288.537 223.168 287.337 222.492 285.656L216.527 270.476H216.507V270.497ZM202.95 278.533L207.872 264.388L212.885 278.533H202.95Z'
        fill='#2F5777'
      />
      <path
        d='M216.824 259.919C214.338 259.919 212.302 261.948 212.302 264.449C212.302 266.95 214.328 268.98 216.824 268.98C219.321 268.98 221.357 266.95 221.357 264.449C221.357 261.948 219.331 259.919 216.824 259.919Z'
        fill='#FEAB61'
      />
      <defs>
        <filter
          id='filter0_d_19923_92162'
          x={170.867}
          y={234.339}
          width={84.561}
          height={84.6568}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={5} />
          <feGaussianBlur stdDeviation={5} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_19923_92162' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_19923_92162' result='shape' />
        </filter>
        <filter
          id='filter1_d_19923_92162'
          x={170.867}
          y={333.343}
          width={84.561}
          height={84.6568}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={5} />
          <feGaussianBlur stdDeviation={5} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_19923_92162' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_19923_92162' result='shape' />
        </filter>
        <filter
          id='filter2_d_19923_92162'
          x={0}
          y={284}
          width={84.561}
          height={84.6568}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={5} />
          <feGaussianBlur stdDeviation={5} />
          <feComposite in2='hardAlpha' operator='out' />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_19923_92162' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_19923_92162' result='shape' />
        </filter>
        <pattern id='pattern0' patternContentUnits='objectBoundingBox' width={1} height={1}>
          <use xlinkHref='#image0_19923_92162' transform='matrix(0.00577919 0 0 0.00518135 -0.00278996 0)' />
        </pattern>
        <linearGradient id='paint0_linear_19923_92162' x1={709.546} y1={249.966} x2={213.407} y2={249.966} gradientUnits='userSpaceOnUse'>
          <stop offset={0.41} stopColor='#38A3A5' stopOpacity={0} />
          <stop offset={1} stopColor='#38A3A5' />
        </linearGradient>
        <linearGradient id='paint1_linear_19923_92162' x1={709.628} y1={237.01} x2={214.45} y2={237.01} gradientUnits='userSpaceOnUse'>
          <stop offset={0.41} stopColor='#38A3A5' stopOpacity={0} />
          <stop offset={1} stopColor='#38A3A5' />
        </linearGradient>
        <linearGradient id='paint2_linear_19923_92162' x1={709.638} y1={224.259} x2={215.402} y2={224.259} gradientUnits='userSpaceOnUse'>
          <stop offset={0.41} stopColor='#38A3A5' stopOpacity={0} />
          <stop offset={1} stopColor='#38A3A5' />
        </linearGradient>
        <linearGradient id='paint3_linear_19923_92162' x1={709.7} y1={213.425} x2={216.354} y2={213.425} gradientUnits='userSpaceOnUse'>
          <stop offset={0.41} stopColor='#38A3A5' stopOpacity={0} />
          <stop offset={1} stopColor='#38A3A5' />
        </linearGradient>
        <linearGradient id='paint4_linear_19923_92162' x1={710.61} y1={208.105} x2={217.305} y2={208.105} gradientUnits='userSpaceOnUse'>
          <stop offset={0.37} stopColor='#38A3A5' stopOpacity={0} />
          <stop offset={1} stopColor='#38A3A5' />
        </linearGradient>
        <image
          id='image0_19923_92162'
          width={174}
          height={193}
          xlinkHref='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAK4AAADBCAYAAABWrothAAAACXBIWXMAAAsSAAALEgHS3X78AAAEOUlEQVR4nO3dUW7cNhiF0V+F9+0spUup3xugWUiDbsBkMaSkkTQy4NcLnCMEGlIU5Zn5YOQpWf759+8fVfXee6/92F6P88N1bh7jSm/n+dM+dTO377jv/3rvzdzLc7+4vj3z7vlj/PizjNetH+b6Ms5tH/ebuXlubdtnXqvrml7n+9qyzi9zbn39XLtUb4d72/VaP6+/3v+Nuerre2pt7Pcc93Xcq63n+byaa9c3PMfPtXPN+BDn57iNL/eP7+qwth+uj/Hj+Gz7z1Prc8Y+p32372Ts8/FHQSDhEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6R3qrqz6r6y9dHkP+W7V8IhyT+qkAk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLpLdfv3/+qKr3x/+ivh/b63F+uM7NY1zp7Tx/2qdu5vYd9/1f772Ze3nuF9e3Z949f4wff5bxuvXDXF/Gue3jfjM3z61t+8xrdV3T63xfW9b5Zc6tr59rl+rtcG+7Xuvn9df7vzFXfX1PrY39nuO+jnu19TyfV3Pt+obn+Ll2rhkf4vwct/Hl/vFdHdb2w/Uxfhyfbf95an3O2Oe07/adjH0+/MYlknCJJFwiCZdIwiWScIkkXCIJl0jCJZJwiSRcIgmXSMIlknCJJFwiCZdIwiWScIkkXCIJl0jCJZJwiSRcIgmXSMIlknCJJFwiCZdIwiWScIkkXCIJl0jL9g8tQxK/cYkkXCIJl0jCJZJwiSRcIgmXSMIlknCJJFwiCZdIwiWScIkkXCIJl0jCJZJwiSRcIgmXSMIlknCJJFwiCZdIwiWScIkkXCIJl0jCJZJwiSRcIgmXSMIlknCJJFwiCZdIwiWScIkkXCIJl0jCJZJwiSRcIgmXSMIlknCJJFwivf36/fNHVb0//vv//dhej/PDdW4e40pv5/nTPnUzt++47/96783cy3O/uL498+75Y/z4s4zXrR/m+jLObR/3m7l5bm3bZ16r65pe5/vass4vc259/Vy7VG+He9v1Wj+vv97/jbnq63tqbez3HPd13Kut5/m8mmvXNzzHz7VzzfgQ5+e4jS/3j+/qsLYfro/x4/hs+89T63PGPqd9t+9k7PPhNy6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm4RBIukYRLJOESSbhEEi6RhEsk4RJJuEQSLpGESyThEkm45Kmq/wGAj1NMq+zXjwAAAABJRU5ErkJggg=='
        />
      </defs>
    </svg>
  );
};
