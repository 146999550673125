import React, { FC, SVGProps } from 'react';

export const Check: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={19} height={18} viewBox='0 0 19 18' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        id='Icon'
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.8801 1.18108L5.6334 12.0351L2.64895 8.84641C2.09918 8.32805 1.23526 8.29664 0.606953 8.73645C-0.00564553 9.19198 -0.17843 9.99306 0.198554 10.6371L3.73277 16.3861C4.07834 16.9201 4.67523 17.25 5.35066 17.25C5.99468 17.25 6.60727 16.9201 6.95284 16.3861C7.51832 15.6478 18.3095 2.78326 18.3095 2.78326C19.7232 1.33815 18.011 0.0658328 16.8801 1.16537V1.18108Z'
        fill='white'
      />
    </svg>
  );
};

export default Check;
