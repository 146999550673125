import React, { FC, SVGProps } from 'react';

export const Contact: FC<SVGProps<SVGSVGElement>> = ({ color = '#57CC99' }) => {
  return (
    <svg width='30' height='24' viewBox='0 0 30 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M8.71279 0.875582C13.1047 0.544553 17.5159 0.566282 21.9044 0.940524L24.5493 1.16607C26.547 1.33645 28.1684 2.85258 28.4722 4.83447L28.639 5.92307C29.2051 9.61712 29.1524 13.3797 28.4824 17.0563C28.1518 18.8705 26.5716 20.1889 24.7276 20.1889H10.1065C9.77007 20.1889 9.43893 20.2721 9.14263 20.4314L2.77259 23.8547C2.394 24.0582 1.93633 24.0478 1.56743 23.8272C1.19853 23.6067 0.972656 23.2086 0.972656 22.7787V8.90541C0.972656 4.70529 4.20982 1.21497 8.39806 0.899298L8.71279 0.875582ZM8.70964 8.93357C7.58515 8.93357 6.67359 9.8451 6.67359 10.9697C6.67359 12.094 7.58515 13.0057 8.70964 13.0057C9.83412 13.0057 10.7457 12.094 10.7457 10.9697C10.7457 9.8451 9.83412 8.93357 8.70964 8.93357ZM15.225 8.93357C14.1004 8.93357 13.1889 9.8451 13.1889 10.9697C13.1889 12.094 14.1004 13.0057 15.225 13.0057C16.3495 13.0057 17.261 12.094 17.261 10.9697C17.261 9.8451 16.3495 8.93357 15.225 8.93357ZM19.7043 10.9697C19.7043 9.8451 20.6158 8.93357 21.7403 8.93357C22.8649 8.93357 23.7764 9.8451 23.7764 10.9697C23.7764 12.094 22.8649 13.0057 21.7403 13.0057C20.6158 13.0057 19.7043 12.094 19.7043 10.9697Z'
        fill={color}
      />
    </svg>
  );
};
