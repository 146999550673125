import React, { FC, SVGProps } from 'react';

interface SkeziProps extends SVGProps<SVGSVGElement> {
  size?: number;
}

export const Skezi: FC<SkeziProps> = ({ size = 1, ...props }) => {
  return (
    <svg width={68 * size} height={14 * size} viewBox='0 0 68 14' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        d='M3.78888 0C-0.198947 0 -2.20008 6.86062 3.72749 8.16053L9.24343 8.17064C11.4749 8.67616 10.9297 11.2724 9.24343 11.5807L0.347736 11.6096V14.0036H9.40448C14.9775 12.8193 13.8819 6.28288 9.40087 5.80481H4.04453C2.17988 5.46539 2.13439 2.6157 4.04453 2.36728L11.7718 2.37377C11.7718 1.66605 11.7718 0.88827 11.7718 0.00361085'
        fill='#2E5877'
      />
      <path
        d='M16.0322 0H18.5764V5.54483H22.8856C25.7945 5.09925 27.2324 3.14505 27.5075 0H30.0517C29.9903 2.78686 29.001 5.15485 26.6539 6.91045C29.071 8.38946 29.9037 10.9236 30.0517 13.9155H27.5241C27.3912 10.9308 26.0076 8.96214 23.0467 8.27391H18.5988V13.9162H16.0322V0Z'
        fill='#2E5877'
      />
      <path d='M67.7217 14V1.90735e-06L65.3508 1.90735e-06V14H67.7217Z' fill='#2E5877' />
      <path
        d='M49.9692 0V2.37089H58.2958C59.4513 2.50521 59.8015 4.15176 58.2958 4.69267L51.1687 7.32714C48.0164 8.66821 48.2518 13.6548 52.1797 13.9162H61.4481V11.5453H52.5329C51.3333 11.2319 51.1983 10.1992 52.3213 9.37378L59.9185 6.55081C63.3531 5.32818 61.7131 0 58.3897 0H49.9692Z'
        fill='#2E5877'
      />
      <path d='M45.9754 11.5453H33.4761V13.9162H45.9754V11.5453Z' fill='url(#paint0_linear_20155_34915)' />
      <path d='M45.9754 5.77231H33.4761V8.1432H45.9754V5.77231Z' fill='url(#paint1_linear_20155_34915)' />
      <path d='M45.9754 0H33.4761V2.37089H45.9754V0Z' fill='url(#paint2_linear_20155_34915)' />
      <defs>
        <linearGradient id='paint0_linear_20155_34915' x1={33.4761} y1={12.7304} x2={45.9754} y2={12.7304} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#D0EDCC' />
          <stop offset={0.33} stopColor='#9AD69B' />
          <stop offset={0.51} stopColor='#77C49C' />
          <stop offset={0.7} stopColor='#55A1A5' />
          <stop offset={1} stopColor='#2E5877' />
        </linearGradient>
        <linearGradient id='paint1_linear_20155_34915' x1={33.4761} y1={6.95811} x2={45.9754} y2={6.95811} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#D0EDCC' />
          <stop offset={0.33} stopColor='#9AD69B' />
          <stop offset={0.51} stopColor='#77C49C' />
          <stop offset={0.7} stopColor='#55A1A5' />
          <stop offset={1} stopColor='#2E5877' />
        </linearGradient>
        <linearGradient id='paint2_linear_20155_34915' x1={33.4761} y1={1.1858} x2={45.9754} y2={1.1858} gradientUnits='userSpaceOnUse'>
          <stop stopColor='#D0EDCC' />
          <stop offset={0.33} stopColor='#9AD69B' />
          <stop offset={0.51} stopColor='#77C49C' />
          <stop offset={0.7} stopColor='#55A1A5' />
          <stop offset={1} stopColor='#2E5877' />
        </linearGradient>
      </defs>
    </svg>
  );
};
