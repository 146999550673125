import React, { FC, SVGProps } from 'react';

export const SkeziaMobile: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={44} height={43} viewBox='0 0 44 43' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <g id='Group 14128'>
        <g id='Vector' filter='url(#filter0_d_18478_107424)'>
          <path
            d='M20.9864 38C30.6589 38 38.5001 30.165 38.5001 20.5C38.5001 10.835 30.6589 3 20.9864 3C11.3138 3 3.47266 10.835 3.47266 20.5C3.47266 30.165 11.3138 38 20.9864 38Z'
            fill='white'
          />
        </g>
        <g id='Group 14115'>
          <path
            id='Vector_16'
            d='M25.214 18.0934C23.4797 17.8312 22.1461 16.3307 22.1461 14.5242C22.1461 13.7044 22.4217 12.9479 22.8838 12.3412C22.8472 12.2824 22.8107 12.2235 22.7714 12.1655C22.5716 11.8675 22.3093 11.6231 21.9837 11.434C21.6581 11.2449 21.2531 11.1494 20.7669 11.1494C20.2807 11.1494 19.8873 11.244 19.5617 11.434C19.2361 11.6231 18.9738 11.8711 18.774 12.178C18.5741 12.4849 18.3859 12.8694 18.2102 13.3333C18.0335 13.7971 17.8828 14.1941 17.7579 14.5242L13.3501 26.0375C13.1698 26.4969 13.0405 26.8556 12.962 27.1143C12.8835 27.3721 12.8442 27.6219 12.8442 27.8645C12.8442 28.2838 13.0128 28.6585 13.3501 28.9894C13.6873 29.3195 14.0744 29.4854 14.5133 29.4854C15.0308 29.4854 15.4028 29.3302 15.6302 29.0198C15.8577 28.7093 16.1316 28.1107 16.4527 27.224L17.2753 24.9501H24.2808L25.1033 27.1758C25.2131 27.4497 25.3442 27.7664 25.4968 28.125C25.6493 28.4836 25.792 28.7495 25.9259 28.9234C26.0588 29.0965 26.2238 29.2339 26.4192 29.3347C26.6154 29.4355 26.8501 29.4854 27.1248 29.4854C27.595 29.4854 27.9964 29.3142 28.33 28.9716C28.6628 28.629 28.8296 28.2517 28.8296 27.8404C28.8296 27.4292 28.653 26.8127 28.3006 25.9421L25.2131 18.0943L25.214 18.0934ZM18.1932 22.2524L20.7437 14.9354L23.3415 22.2524H18.1932Z'
            fill='#2F5777'
            stroke='#2F5777'
            strokeWidth={0.111096}
            strokeMiterlimit={10}
          />
          <path
            id='Vector_20'
            d='M25.7576 11.9836C24.357 11.9836 23.2178 13.1228 23.2178 14.5234C23.2178 15.924 24.357 17.0632 25.7576 17.0632C27.1581 17.0632 28.2964 15.924 28.2964 14.5234C28.2964 13.1228 27.1572 11.9836 25.7576 11.9836Z'
            fill='#FEAB61'
          />
        </g>
      </g>
      <defs>
        <filter
          id='filter0_d_18478_107424'
          x={0.472656}
          y={0}
          width={43.0273}
          height={43}
          filterUnits='userSpaceOnUse'
          colorInterpolationFilters='sRGB'
        >
          <feFlood floodOpacity={0} result='BackgroundImageFix' />
          <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
          <feOffset dx={1} dy={1} />
          <feGaussianBlur stdDeviation={2} />
          <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0' />
          <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_18478_107424' />
          <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_18478_107424' result='shape' />
        </filter>
      </defs>
    </svg>
  );
};
