import React from 'react';
import FormHeader from './FormHeader';
import { BasicButton } from '../common/Button';

type ErrorFormProps = {
  onClick: () => void;
};

const ErrorForm = ({ onClick }: ErrorFormProps) => {
  return (
    <>
      <FormHeader title={`Un problème technique est survenu`} subTitle={`Désolé, une erreur est survenue. Veuillez réessayer plus tard.`} />
      <BasicButton label='Réessayer' fullWidth={true} onClick={onClick} />
    </>
  );
};

export default ErrorForm;
