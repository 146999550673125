import { createRoot } from 'react-dom/client';
import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom';
import DashboardLayout from './components/common/DashboardLayout';
import Layout from './components/common/Layout';
import { Matomo } from './components/common/Matomo';
import { SupportButton } from './components/common/SupportButton';
import './index.css';
import ApiConnections from './pages/ApiConnections/ApiConnections';
import ConnectionEdit from './pages/ApiConnections/ConnectionEdit';
import CheckUser from './pages/CkeckUser/CheckUser';
import DashboardApps from './pages/DashboardApps/DashboardApps';
import DashboardAuthentication from './pages/DashboardAuthentication/DashboardAuthentication';
import DashboardAuthenticationDoubleAuthentication from './pages/DashboardAuthenticationDoubleAuthentication/DashboardAuthenticationDoubleAuthentication';
import DashboardAuthenticationEmail from './pages/DashboardAuthenticationEmail/DashboardAuthenticationEmail';
import DashboardAuthenticationNickName from './pages/DashboardAuthenticationNickName/DashboardAuthenticationNickName';
import DashboardAuthenticationPassword from './pages/DashboardAuthenticationPassword/DashboardAuthenticationPassword';
import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import ResetNickname from './pages/ResetNickname/ResetNickname';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Subscription from './pages/Subscription/Subscription';
import reportWebVitals from './reportWebVitals';

const App = () => {
  return (
    <>
      <Matomo.Wrapper>
        <Routes>
          <Route element={<Layout Outlet={Outlet} />}>
            <Route path={`/login`} Component={Login} />
            <Route path={`/subscription`} Component={Subscription} />
            <Route path={`/reset-nickname`} Component={ResetNickname} />
            <Route path={`/reset-password`} Component={ResetPassword} />
            <Route path={`/check-user`} Component={CheckUser} />
            <Route path={`/logout`} Component={Logout} />
          </Route>
          <Route element={<DashboardLayout Outlet={Outlet} />}>
            <Route path={`/dashboard/apps`} Component={DashboardApps} />
            <Route path={`/dashboard/authentication`} Component={DashboardAuthentication} />
            <Route path={`/dashboard/authentication/email`} Component={DashboardAuthenticationEmail} />
            <Route path={`/dashboard/authentication/nickName`} Component={DashboardAuthenticationNickName} />
            <Route path={`/dashboard/authentication/password`} Component={DashboardAuthenticationPassword} />
            <Route path={`/dashboard/authentication/doubleAuthentication`} Component={DashboardAuthenticationDoubleAuthentication} />
            <Route path={`/dashboard/api-connections`} Component={ApiConnections} />
            <Route path={`/dashboard/api-connections/:appId`} Component={ConnectionEdit} />
          </Route>
          <Route path='*' element={<Navigate to='/dashboard/authentication' />} />
          <Route element={<Navigate to='/login' />} />
        </Routes>
        <SupportButton />
      </Matomo.Wrapper>
    </>
  );
};

const app = document.getElementById('root') as HTMLElement;
const root = createRoot(app);

root.render(
  <Matomo.Provider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Matomo.Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
