import React from 'react';
import { Modal } from '../../components/common/Modal';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { BasicButton } from '../../components/common/Button';

type ConfirmationModalProps = {
  modaleCreatedAccountOpen: boolean;
  setModaleCreatedAccountOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const ConfirmationModal = ({ modaleCreatedAccountOpen, setModaleCreatedAccountOpen }: ConfirmationModalProps) => {
  const navigate: NavigateFunction = useNavigate();

  return (
    <Modal
      modalOpen={modaleCreatedAccountOpen}
      setModalOpenToFalse={() => {
        setModaleCreatedAccountOpen(false);
        navigate(`/login`);
      }}
    >
      <div className='py-5 text-center text-2xl font-bold text-green-dark'>Félicitations !</div>
      <div className='text-center text-sm text-gray-primary'>
        Votre compte a été créé avec succès ! <br />
        Un e-mail de confirmation vient de vous être envoyé pour activer votre compte.
      </div>

      <div className='flex justify-center'>
        <BasicButton
          label='Fermer'
          fullWidth={false}
          onClick={() => {
            setModaleCreatedAccountOpen(false);
            navigate(`/login`);
          }}
        />
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
