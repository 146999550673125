import React, { FC, SVGProps } from 'react';

export const EyeCrossed: FC<SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg width={24} height={24} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M18.0073 15.1157C18.8186 14.3833 19.484 13.6416 19.9583 13.0657C20.3465 12.5944 20.5405 12.3588 20.5405 12C20.5405 11.6412 20.3465 11.4056 19.9583 10.9343C18.5382 9.21014 15.406 6 11.7703 6C10.9308 6 10.1181 6.17115 9.34722 6.45563L11.8941 9.00251C13.4525 9.06577 14.7045 10.3178 14.7678 11.8762L18.0073 15.1157ZM9.16334 10.5144C8.91321 10.9524 8.77027 11.4595 8.77027 12C8.77027 13.6569 10.1134 15 11.7703 15C12.3108 15 12.8179 14.8571 13.2559 14.6069L15.5575 16.9085C14.4063 17.5555 13.1231 18 11.7703 18C8.13454 18 5.00233 14.7899 3.58224 13.0657C3.19408 12.5944 3 12.3588 3 12C3 11.6412 3.19408 11.4056 3.58224 10.9343C4.25567 10.1167 5.31411 8.96489 6.62869 7.97974L9.16334 10.5144Z'
        fill='#57CC99'
      />
      <path d='M4 4L20 20' stroke='#57CC99' strokeWidth={2} />
    </svg>
  );
};
