import { FC, SVGProps } from 'react';

export const Spin: FC<SVGProps<SVGSVGElement>> = ({ color = '#BAC7DC', width = 24, ...props }) => {
  return (
    <svg viewBox='0 0 50 50' xmlns='http://www.w3.org/2000/svg' width={width} {...props}>
      <circle
        cx='25'
        cy='25'
        r='20'
        stroke={color}
        strokeWidth={4}
        fill='transparent'
        className='animate-spin'
        style={{ strokeDasharray: 283, strokeLinecap: 'round', strokeDashoffset: 240, transformOrigin: '50% 50%' }}
      />
      <circle cx='25' cy='25' r='20' stroke={color} strokeWidth={4} fill='transparent' className='opacity-25' />
    </svg>
  );
};
